export const en = {
  header: {
    services: "Services",
    projects: "Projects",
    contact: "Contact"
  },
  hero: {
    title: "AI Solutions & Data Science Consulting"
  },
  services: {
    title: "Services",
    chatbots: {
      title: "AI Chatbots",
      description: "Custom chatbot solutions powered by advanced NLP and machine learning"
    },
    rag: {
      title: "RAG Systems",
      description: "Retrieval Augmented Generation implementations for enhanced AI applications"
    },
    cloud: {
      title: "Cloud Solutions",
      description: "Scalable AI infrastructure on AWS and GCP platforms"
    },
    ai: {
      title: "AI Applications",
      description: "Custom AI-powered applications for business automation and digital transformation"
    }
  },
  projects: {
    title: "Featured Projects",
    features: {
      title: "Key Features"
    },
    speakerAI: {
      title: "Speaker AI Assistant",
      description: "An intelligent recommendation system that matches event organizers with the perfect speakers. Built for Speaker Agency, this AI-powered assistant leverages advanced AI technologies including RAG, LLMs, and speech-to-text processing.",
      features: {
        nlp: "Natural language input processing",
        matching: "Intelligent speaker matching",
        video: "Video content analysis",
        multilingual: "Multilingual support (EN/TR)"
      },
      implementation: {
        vectorDb: {
          title: "Vector Database",
          description: "RAG implementation for efficient information retrieval"
        },
        generativeAi: {
          title: "Generative AI",
          description: "LLM for natural language understanding and response generation"
        },
        speechToText: {
          title: "Speech-to-Text",
          description: "Speech-to-text processing for video content analysis"
        }
      },
      viewProject: "View Project"
    },
    dolunAI: {
      title: "DolunAI",
      description: "An advanced astrology application that generates personalized birth chart readings and daily horoscope predictions. Features an AI avatar on X (Twitter) sharing daily insights on lifestyle and relationships.",
      features: {
        birthChart: "Birth chart generation",
        horoscope: "Personalized horoscope readings",
        lifestyle: "AI-powered lifestyle advice",
        social: "Social media automation"
      },
      implementation: {
        astrology: {
          title: "Astrology Engine",
          description: "Advanced algorithms for accurate birth chart calculations"
        },
        avatar: {
          title: "AI Avatar",
          description: "Personalized social media presence as Dolunay Varlı"
        },
        content: {
          title: "Content Generation",
          description: "AI-powered horoscope and lifestyle content creation"
        }
      }
    }
  },
  contact: {
    title: "Let's Work Together",
    subtitle: "Ready to transform your business with cutting-edge AI solutions? Get in touch and let's discuss how we can help you achieve your goals.",
    info: {
      title: "Contact Information",
      address: {
        name: "Istavrit AI",
        street: "İdealtepe mahallesi Dik sokak 13/2",
        city: "Maltepe İstanbul 34841",
        country: "Türkiye"
      }
    },
    cta: "Contact Us"
  }
};